import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome'
import axios from 'axios';
import { SERVICE_URL } from '../constant';
export default function HireTrainDeploy() { 

  const [inputs, setInputs] = useState({
    fullName: '',
    email: '',
    designation: '',
    company: '',
    mobile: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [validations, setValidations] = React.useState({
    fullName: '',
    email: '',
    designation: '',
    company: '',
    mobile: '',
    errorMessage: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleFieldValidation = (field, errorMessage) => {
    if (!inputs[field]) {
      setValidations(prevState => ({
        ...prevState,
        [field]: errorMessage
      }));
      return false;
    } else {

      if (field === 'fullName') {
        const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
        if (!pattern.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter full Name'
          }));
          return false;
        }
      }

      if (field === 'designation') {
        const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
        if (!pattern.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter Designation'
          }));
          return false;
        }
      }

      if (field === 'mobile') {
        const phoneRegex = /^(?:\+(?:[0-9] ?){6,14}[0-9]|(?:\+?1[2-9]\d{2}[2-9](?!11)\d{6})|(?:[789]\d{9}))$/;
        if (!phoneRegex.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter valid mobile Number'
          }));
          return false;
        }
      }

      if (field === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter valid Email'
          }));
          return false;
        }
      }

      else {
        setValidations(prevState => ({
          ...prevState,
          [field]: ''
        }));
      }
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isFormValid = true;
    setValidations(prevState => ({
      ...prevState,
      fullName: '',
      company: '',
      email: '',
      mobile: '',
      designation: '',
    }));

    // Check each field and set error messages if required
    if (!handleFieldValidation('fullName', 'Please enter your full Name')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('company', 'Please enter your Company Name')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('designation', 'Please enter your Designation')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('email', 'Please enter your Email')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('mobile', 'Please enter your Phone')) {
      isFormValid = false;
    }

    if (isFormValid) {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
        }
      };
      var data = {
        fullname: inputs.fullName,
        emailId: inputs.email,
        phone: inputs.mobile,
        company_name: inputs.company,
        designation: inputs.designation,
        contact_reason: "Hire from us",
      };
      try {
        const result = await axios.post(SERVICE_URL + "/enquiry/company", data, requestOptions, { crossDomain: true });
        console.log(result);
        if (result.data.statusCode === "201") {
          setSuccess(true);
          setErrorMsg('');
          setError(false);
          setInputs({});
        }
        else {
          setErrorMsg(result.data.detail.message);
          setSuccess(false);
          setError(true);
        }

      }
      catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Bridge your Talent demand-supply gap with Skillible</title>
          <meta name="description" content="Our HTD model aims to provide value to job aspirants, empowering young people with the skills and knowledge necessary to meet the growing demand of industry." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
          <meta property="og:description" content="Our HTD model aims to provide value to job aspirants, empowering young people with the skills and knowledge necessary to meet the growing demand of industry." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />

        </Helmet>
      </HelmetProvider>
      <div>
        {/* <section className="industries-banner she-leads">
          <div className="container">
            <div className="row">
              <div className="col-md-6">

                <div className="text-banner hire-tr-td">
                  <h1>Looking Out  <span>For Trained</span> Industry Resources?? </h1>
                  <p> Let's start Bridging Talent <span>demand-supply gap with</span> Skillible !!!</p>

                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner pair-imgdv ndvt-2 hire-from-us-s">
                  <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                  <div className="pairbanner_girl">
                    <img src={require(`../assets/images/banner_girl2.png`)} className="img-fluid" alt="Hire with Skillible" />
                  </div>

                  <div className="pewilly">

                    <div className="pewilly-img">
                      <img src={require(`../assets/images/build.png`)} className="img-fluid" alt="Expand your workforce with Skillible" />
                    </div>
                    <div className="pewilly-text">
                      <p>Build <span>We can expand your workforce and contrive skill needs </span></p>

                    </div>
                  </div>
                  <div className="pewilly-operate">
                    <div className="pewilly-img">
                      <img src={require(`../assets/images/operate.png`)} className="img-fluid" alt="Live Bootstrap Training" />
                    </div>
                    <div className="pewilly-text">
                      <p>Operate <span>Benefit from live bootstrap training to upskill your workforce. </span></p>
                    </div>
                  </div>
                  <div className="pewilly-transfer">
                    <div className="pewilly-img">
                      <img src={require(`../assets/images/trasfer.png`)} className="img-fluid" alt="Deployment Ready Candidates from Day 1" />
                    </div>
                    <div className="pewilly-text">
                      <p>Transfer <span>Our Trained Talents are ready to work on client projects from day 1.</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <section className="media-banner htd">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <div className="text-banner hire-tr-td">
                     <h3>  </h3>
                     <h1>Empowering Futures: Hire-Train-Deploy</h1>
                     <p className="report-para">  Skillible’s Hire-Train-Deploy (HTD) program is designed to bridge the talent gap with precision. We identify top candidates, provide customized training aligned with your business needs, and deploy industry-ready professionals who deliver results from day one. Streamline hiring, reduce onboarding time, and ensure workforce scalability with our comprehensive HTD solutions.</p>
                  </div>
               </div>
            </div>
         </div>
      </section>

        {/* <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
               <div className="container">
                  <div className="row mx-wth">
                     <div className="col-md-12 col-lg-6">
                        <div className="frenceimg-pair">
                           <div className="img-frence-pair"><img src={require(`../assets/images/candidate-dep.png`)} className="img-fluid" alt="Hire Deployment-Ready Candidates from Day One - Skillible" />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                        <div className="languapair-steps step1 day-dep-ts">
                           <h2>Empowering Future:  <span className="day-one-skil"> Hire the Best, Train for Excellence, Deploy with Precision</span>
                           </h2>
                        
                          
                          <p>At Skillible, we bridge the gap between talent and opportunity with our AI-driven Hire-Train-Deploy (HTD) program. Our innovative process ensures that organizations
                             can access industry-ready professionals, tailored to their specific needs.  </p>
                             <p>Here's how our program addresses key challenges faced
                             by the tech industry and sets a new benchmark in talent acquisition and training.</p>
                          
                        </div>
                     </div>
                  </div>
               </div>
            </section> */}


 <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
               <div className="container">
                  <div className="row mx-wth">
                    
                  <div className="col-md-12 col-lg-7">
                <div className="languapair-steps step1">
                  <h2>Challenges Faced by the Tech Industry </h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""> <strong>Skill Gap: </strong>  Rapid tech advancements leave a shortage of job-ready professionals. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>High Attrition Rates: </strong>  Constant turnover increases recruitment and training costs.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Lengthy Onboarding: </strong>   Fresh hires often require significant time to become productive. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Rising Hiring Costs: </strong>  Recruitment, onboarding, and upskilling require high investments.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Tech Evolution: </strong>  Keeping up with the latest tools and trends is critical but challenging. </div>
                    </li>
                  </ul>
                </div>
              </div>
                     <div className="col-md-12 col-lg-5">
                        <div className="frenceimg-pair">
                           <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/challenging.png`)} className="img-fluid" alt="Hire Deployment-Ready Candidates from Day One - Skillible" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>


            <section className="hire-skillible-s hd2 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="htd-skillible-rtd">
                  <h2>Our AI-Led HTD Process </h2>
                  <p>At Skillible, we bridge the gap between talent and opportunity with our AI-driven Hire-Train-Deploy (HTD) program. Our innovative process ensures that organizations can access industry-ready professionals, tailored to their specific needs. Here's how our program addresses key challenges faced by the tech industry and sets a new benchmark in talent acquisition and training.

</p>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/htd-discover.png`)} className="img-fluid" alt="Hire and customize talent as per your need" />
                    {/* <div className="htd-skillible">
                      <div className="htd-skillible-img">
                        <img src={require(`../assets/images/hiring-icon-h.png`)} className="img-fluid" alt="" />
                      </div>
                      <div className="pewilly-text-hire">
                        <p>Discover <span>Not just freshers, recruit mid-level experienced professionals
                          through tests and interviews  </span></p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1">
                  <h2>Discover</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""> <strong>Access Talent Pools: </strong> Partner with colleges for fresh talent. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Focus on Key Tech: </strong> Target emerging tech and in-demand skills. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Assess Early: </strong>  Shortlist high-potential candidates through assessments.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Institutional Ties: </strong>  Build relationships with universities for early access.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Data-Driven Screening: </strong>  Use AI tools for skill matching.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/Hire-skillible-htd.png`)} className="img-fluid" alt="Hire trained candidates with Skillible" />

                    {/* <div className="hire-htd-skillible">
                      <div className="hire-htd-skillible-img">
                        <img src={require(`../assets/images/skill-train.png`)} className="img-fluid" alt="Customized training with Skillible" />
                      </div>
                      <div className="pewilly-text-train">
                       
                        <p>Hire <span>Not just freshers, recruit mid-level experienced professionals
                        through tests and interviews  </span></p>
                      </div>

                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>Hire</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Customized Hiring: </strong> Tailor hiring based on skills needed. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Flexible Options: </strong> Offer internships or direct recruitment.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Fast Onboarding: </strong> Streamline hiring processes for quick starts. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Industry-Focused: </strong> Hire candidates aligned with industry needs. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Pre-Hire Agreements: </strong>  Secure commitment before training.</div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/Train-skillible.png`)} className="img-fluid" alt="Hire deploy ready candidates from Day 1 -Skillible" />

                    {/* <div className="htd-skillible">
                      <div className="htd-skillible-img">
                        <img src={require(`../assets/images/deploy.png`)} className="img-fluid" alt="Reduce attrition rate with Skillible" />
                      </div>
                      <div className="pewilly-text-hire">
                        
                        <p>Train <span>An opportunity to select candidates as per training charter and skill requirements.</span>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1">
                  <h2>Train</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Industry-Specific Curriculum:  </strong> Tailored training aligned with sector demands, ensuring relevance. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Hands-On Experience:  </strong>   Real-world projects and simulations for practical learning.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">
                      <strong>Expert-Led Sessions:</strong> Training delivered by industry professionals and subject matter experts. 
                      </div>
                    </li>
                    
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">
                      <strong>Soft Skills Integration: </strong> Focus on business communication, teamwork, and leadership skills. 
                      </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">
                      <strong>Customizable Modules: </strong> Flexible training paths based on client needs and emerging technologies. 
                      </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">
                      <strong>Continuous Assessment: </strong> Regular evaluations to track progress and ensure readiness.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>




       



        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/deploy-skillble.png`)} className="img-fluid" alt="Hire trained candidates with Skillible" />

                    {/* <div className="hire-htd-skillible">
                      <div className="hire-htd-skillible-img">
                        <img src={require(`../assets/images/skill-train.png`)} className="img-fluid" alt="Customized training with Skillible" />
                      </div>
                      <div className="pewilly-text-train">
                       
                        <p>Depoly <span>From day one, candidates may be employed as FTE or CWR resources.</span></p>
                      </div>

                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>Deploy</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""> <strong>Ready-to-Work: </strong>  Deploy fully trained candidates.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Smooth Onboarding: </strong> Ensure easy integration into teams.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Ongoing Support: </strong>  Provide post-deployment mentorship. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Scalable Solutions: </strong>  Deploy talent as needed—batch or individual. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong>Flexible Terms:  </strong> Offer short or long-term deployment options. </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="pair-sectiontext hd2 ndvt-3">
                       <div className="container">
                          <div className="row displ-lrtd mx-wth">


                          <div className="col-md-12 col-lg-6">
                                <div className="languapair-steps step1 ">
                                   <h2>Why Choose Skillible? </h2>
                                
                                        <p>Partner with <strong>Skillible</strong>  to access a meticulously trained <strong>talent pool </strong>  and comprehensive <strong> talent development </strong> solutions.
                                          From <strong> talent acquisition </strong> and <strong>upskilling </strong>  to seamless <strong>placement </strong> , we deliver end-to-end support tailored to meet your hiring needs.
                                        </p>

                                   <ul>
                                      <li>
                                         <span className="icon"><i className="fas fa-check"></i></span>
                                         <div className=""><strong>End-to-End Solutions: </strong>  From sourcing to deployment, we handle it all.  </div>
                                      </li>
                                      <li>
                                         <span className="icon"><i className="fas fa-check"></i></span>
                                         <div className=""><strong>AI-Driven Precision: </strong>  Leverage advanced tools to match talent with industry needs. </div>
                                      </li>
                                      <li>
                                         <span className="icon"><i className="fas fa-check"></i></span>
                                         <div className=""><strong>Cost-Effective: </strong>  Reduce hiring and training expenses with our efficient processes. </div>
                                      </li>
                                      <li>
                                         <span className="icon"><i className="fas fa-check"></i></span>
                                         <div className=""><strong>Enhanced Productivity: </strong>  Deploy skilled professionals who contribute from day one.  </div>
                                      </li>

                                      <li>
                                         <span className="icon"><i className="fas fa-check"></i></span>
                                         <div className=""><strong>Future-Ready Workforce: </strong>  Train candidates in emerging technologies and market trends.  </div>
                                      </li>
                                   </ul>
                                   
                                </div>
                             </div>


                             <div className="col-md-12 col-lg-6 text-end">
                                <div className="frenceimg-pair">
                                   <div className="img-frence-pair"><img src={require(`../assets/images/why-skill.png`)} className="img-fluid" alt="Partner with skillible" />
                                   </div>
                                </div>
                             </div>
                           
                          </div>
                       </div>
                    </section> */}


{/* 
        <section className="hire-skillible-s hd2 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="">
                  <h2>Why Choose Skillible?
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section> */}











<section className="interpretr-sec1 sce991 mt-6">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2> Why  
                  <span> Choose </span> Skillible? </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-7 float-end">
                <div className="inter-li1">
                  <span>
                    <img src={require(`../assets/images/no-cost-earning.png`)} className="img-fluid" alt="No Cost learning opportunity - Skillible" />
                  </span>
                  <div className="">
                    <p><strong>End-to-End Solutions: </strong> From sourcing to deployment, we handle it all.</p>
                  </div>
                </div>
                <div className="inter-li1 inter-li2">
                  <span>
                    <img src={require(`../assets/images/placement-icon.png`)} className="img-fluid" alt="Placement opportunities - Skillible" />
                  </span>
                  <div className="">
                    <p><strong>AI-Driven Precision: </strong> Leverage advanced tools to match talent with industry needs. </p>
                  </div>
                </div>
                <div className="inter-li1 inter-li3">
                  <span>
                    <img src={require(`../assets/images/emi-o.png`)} className="img-fluid" alt="Flexible EMI Options: 6 to 36 Months! - Skillible" />
                  </span>
                  <div className="">
                    <p><strong>Cost-Effective: </strong> Reduce hiring and training expenses with our efficient processes.
                    </p>
                  </div>
                </div>
                <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/classroom.png`)} className="img-fluid" alt=" Instructor led classroom - Skillible" />
                  </span>
                  <div className="">
                    <p><strong>Enhanced Productivity: </strong> Deploy skilled professionals who contribute from day one. </p>
                  </div>
                </div>
                {/* <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/classroom.png`)} className="img-fluid" alt=" Instructor led classroom - Skillible" />
                  </span>
                  <div className="">
                    <p><strong>Future-Ready Workforce: </strong> Train candidates in emerging technologies and market trends.
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="col-md-5 text-center float-start">
                <span>
                  <img src={require(`../assets/images/home-revamp/why-choose.png`)} 
                  className="img-fluid" alt="Key Benefits Of Enrolling With Skillible" />
                </span>
              </div>
            </div>
          </div>
        </section>







      


   









<section className="foundationl-cources programe-offer-skil">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2">
                <h2> Case Studies  </h2>
              </div>
              <div className="col-md-6">
                <div className="foundational-cour">
                  <div className="foundationl-cources3">
                    <span>
                      <img src={require(`../assets/images/home-revamp/salesforce.png`)} className="img-fluid" alt="Programs offered by Skillible - Salesforce Development Program   " />
                    </span>
                  </div>
                  <div className="foundationl-cources4 casestudies-skillible">
                    <div className="foundationl-cources5">
                      <h2 className=''>Accelerating Digital Transformation with Salesforce Expertise</h2>
                      <p> A leading automobile manufacturer partnered with Skillible to deploy Salesforce-trained professionals
                     through a Hire-to-Deploy model. This approach enhanced customer engagement, streamlined operations,
                      and reduced recruitment costs. By providing pre-trained talent in Salesforce Customer 360 and tailored 
                      industry-specific training, the company achieved personalized customer experiences, operational efficiency,
                       and a scalable workforce for sustained growth.</p>   {/* <h4 className="bottom-line-h">
                        <Link href="Salesforce"> Learn more </Link>
                      </h4> */}
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="foundational-cour">
                  <div className="foundationl-cources3">
                    <span>
                      <img src={require(`../assets/images/home-revamp/cybersercuirty.png`)} className="img-fluid" alt="Programs offered by Skillible - Full-Stack Development" />
                    </span>
                  </div>
                  <div className="foundationl-cources4 casestudies-skillible">
                    <div className="foundationl-cources5">
                      <h2> Strengthening Cybersecurity with Skilled Professionals </h2>
                      <p> A renowned IT company collaborated with Skillible to deploy cybersecurity experts through a Hire-to-Deploy model. Certified in SIEM, Endpoint Protection, and Cloud Security, these professionals enhanced data security and incident response. Tailored training ensured agile deployment, achieving operational efficiency, cost savings, and a scalable talent pool, enabling secure innovation and uninterrupted digital transformation. </p>

                      {/* <h4 className="bottom-line-h">
                        <Link href="Fullstack"> Learn more </Link>
                      </h4> */}
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




       




       
    

      
      </div>

    </React.Fragment>
  )
}