import { BrowserRouter as Router, Routes , Route }  from "react-router-dom";
import HeaderHome from './components/HeaderHome';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Salesforce from './components/Salesforce';
import Fullstack from './components/Fullstack';
import SheTrails from './components/SheTrails';
import TechScaller from './components/TechScaller';
import HireFromUs from './components/HireFromUs'; 
import HireTrainDeploy from './components/HireTrainDeploy';
import Faq from './components/Faq'; 
import Career from './components/Career'; 
import PrivacyPolicy from './components/PrivacyPolicy'; 
import TermsOfUse from './components/TermsOfUse'; 
import Demo from './components/Demo';
import SoftwareTesting from './components/SoftwareTesting';
import JoinHiringPartner from './components/JoinHiringPartner';
import Sitemap from './components/Sitemap';
import Error from './components/Error';
import ErrorBoundary from './components/ErrorBoundary';
import Brochure from './components/Brochure';
import BusinessAdministration from './components/BusinessAdministration';
import GenAI from './components/GenAI';
import Virtualtermsofuse from './components/Virtualtermsofuse';
import DefaultApplyNowForm from "./components/DefaultApplyNowForm";
import Pressmedia from "./components/Pressmedia";
import VerifyCertificate from "./components/VerifyCertificate";
import Sucess_stories from "./components/Sucess_stories";
import Report from "./components/report";
import Cybersecurity from "./components/cybersecurity";
import Vlsi from "./components/Vlsi";
import Disclaimer_tns from "./components/Disclaimer_tns";



function App() {
  return (
    
    <Router>
   
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="salesforce" element={<Salesforce />} />
          <Route path="fullstack" element={<Fullstack />} />
          <Route path="she-trails" element={<SheTrails />} />
          <Route path="tech-scaller" element={<TechScaller />} />
          <Route path="hire-from-us" element={<HireFromUs />} />
          <Route path="virtual_internship" element={<BusinessAdministration />} />
          <Route path="press_media" element={<Pressmedia />} />
          <Route path="genai" element={<GenAI />} />
          <Route path="hire-train-deploy" element={<HireTrainDeploy />} />
          <Route path="about" element={<About />} />
          <Route path="sheTrails" element={<SheTrails />} />
          <Route path="faq" element={<Faq />} />
          <Route path="/applynow" element= {<DefaultApplyNowForm />} />
          <Route path="career" element={<Career />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="virtual_internship_termsofuse" element={<Virtualtermsofuse />} />
          <Route path="HeaderHome" element={<HeaderHome />} />
          {/* <Route path="Header2" element={<Header2 />} /> */}
          <Route path="software-testing" element={<SoftwareTesting />} />
          <Route path="demo" element={<Demo />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="verifycertificate" element={<VerifyCertificate />} />
          <Route path="verifycertificate/:identifier" element={<VerifyCertificate />} />
          <Route path="*" element={<Error />} />
          <Route path="ErrorBoundary" element={<ErrorBoundary />} />
          <Route path="join-hiring-partner" element={<JoinHiringPartner />} />
          <Route path="brochure/salesforce/salesforce-july23" element={<Brochure />} /> 
          <Route path="Sucess_stories" element={<Sucess_stories />} /> 
          <Route path="report" element={<Report />} /> 
          <Route path="Cybersecurity" element={<Cybersecurity />} />
          <Route path="Vlsi" element={<Vlsi />} />
          <Route path="Disclaimer_tns" element={<Disclaimer_tns />} />
        </Route>
      </Routes>
      
    <Footer/>
    </Router>

  );
}

export default App;
